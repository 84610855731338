<!--
 * @Author: Akarichan
 * @LastEditors: Akarichan
 * @Date: 2021-09-27 14:29:09
 * @LastEditTime: 2021-10-09 10:58:45
-->
<template>
    <div>
        <h2>图标</h2>
        <p>直接通过设置类名为 el-icon-iconName 来使用即可。例如：</p>
        <pre v-highlightjs="data" class="components_code">
            <code class="javascript"></code>
        </pre>
        <div class="icon_block">
            <ul class="icon_lists dib-box">
                <li class="dib">
                <span class="icon iconfont um-icon-3column"></span>
                <div class="name">
                    column-3
                </div>
                <div class="code-name">.um-icon-3column
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-column-4"></span>
                <div class="name">
                    column-4
                </div>
                <div class="code-name">.um-icon-column-4
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-add"></span>
                <div class="name">
                    add
                </div>
                <div class="code-name">.um-icon-add
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-add-circle"></span>
                <div class="name">
                    add-circle
                </div>
                <div class="code-name">.um-icon-add-circle
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-adjust"></span>
                <div class="name">
                    adjust
                </div>
                <div class="code-name">.um-icon-adjust
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-arrow-up-circle"></span>
                <div class="name">
                    arrow-up-circle
                </div>
                <div class="code-name">.um-icon-arrow-up-circle
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-arrow-right-circle"></span>
                <div class="name">
                    arrow-right-circle
                </div>
                <div class="code-name">.um-icon-arrow-right-circle
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-arrow-down"></span>
                <div class="name">
                    arrow-down
                </div>
                <div class="code-name">.um-icon-arrow-down
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-ashbin"></span>
                <div class="name">
                    ashbin
                </div>
                <div class="code-name">.um-icon-ashbin
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-arrow-right"></span>
                <div class="name">
                    arrow-right
                </div>
                <div class="code-name">.um-icon-arrow-right
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-browse"></span>
                <div class="name">
                    browse
                </div>
                <div class="code-name">.um-icon-browse
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-bottom"></span>
                <div class="name">
                    bottom
                </div>
                <div class="code-name">.um-icon-bottom
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-back"></span>
                <div class="name">
                    back
                </div>
                <div class="code-name">.um-icon-back
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-bad"></span>
                <div class="name">
                    bad
                </div>
                <div class="code-name">.um-icon-bad
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-arrow-double-left"></span>
                <div class="name">
                    arrow-double-left
                </div>
                <div class="code-name">.um-icon-arrow-double-left
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-arrow-left-circle"></span>
                <div class="name">
                    arrow-left-circle
                </div>
                <div class="code-name">.um-icon-arrow-left-circle
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-arrow-double-right"></span>
                <div class="name">
                    arrow-double-right
                </div>
                <div class="code-name">.um-icon-arrow-double-right
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-caps-lock"></span>
                <div class="name">
                    caps-lock
                </div>
                <div class="code-name">.um-icon-caps-lock
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-camera"></span>
                <div class="name">
                    camera
                </div>
                <div class="code-name">.um-icon-camera
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-chart-bar"></span>
                <div class="name">
                    chart-bar
                </div>
                <div class="code-name">.um-icon-chart-bar
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-attachment"></span>
                <div class="name">
                    attachment
                </div>
                <div class="code-name">.um-icon-attachment
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-code"></span>
                <div class="name">
                    code
                </div>
                <div class="code-name">.um-icon-code
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-close"></span>
                <div class="name">
                    close
                </div>
                <div class="code-name">.um-icon-close
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-check-item"></span>
                <div class="name">
                    check-item
                </div>
                <div class="code-name">.um-icon-check-item
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-calendar"></span>
                <div class="name">
                    calendar
                </div>
                <div class="code-name">.um-icon-calendar
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-comment"></span>
                <div class="name">
                    comment
                </div>
                <div class="code-name">.um-icon-comment
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-column-vertical"></span>
                <div class="name">
                    column-vertical
                </div>
                <div class="code-name">.um-icon-column-vertical
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-column-horizontal"></span>
                <div class="name">
                    column-horizontal
                </div>
                <div class="code-name">.um-icon-column-horizontal
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-complete"></span>
                <div class="name">
                    complete
                </div>
                <div class="code-name">.um-icon-complete
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-chart-pie"></span>
                <div class="name">
                    chart-pie
                </div>
                <div class="code-name">.um-icon-chart-pie
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-cry"></span>
                <div class="name">
                    cry
                </div>
                <div class="code-name">.um-icon-cry
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-customer-service"></span>
                <div class="name">
                    customer-service
                </div>
                <div class="code-name">.um-icon-customer-service
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-delete"></span>
                <div class="name">
                    delete
                </div>
                <div class="code-name">.um-icon-delete
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-direction-down"></span>
                <div class="name">
                    direction-down
                </div>
                <div class="code-name">.um-icon-direction-down
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-copy"></span>
                <div class="name">
                    copy
                </div>
                <div class="code-name">.um-icon-copy
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-cut"></span>
                <div class="name">
                    cut
                </div>
                <div class="code-name">.um-icon-cut
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-data-view"></span>
                <div class="name">
                    data-view
                </div>
                <div class="code-name">.um-icon-data-view
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-direction-down-circle"></span>
                <div class="name">
                    direction-down-circle
                </div>
                <div class="code-name">.um-icon-direction-down-circle
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-direction-right"></span>
                <div class="name">
                    direction-right
                </div>
                <div class="code-name">.um-icon-direction-right
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-direction-up"></span>
                <div class="name">
                    direction-up
                </div>
                <div class="code-name">.um-icon-direction-up
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-discount"></span>
                <div class="name">
                    discount
                </div>
                <div class="code-name">.um-icon-discount
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-direction-left"></span>
                <div class="name">
                    direction-left
                </div>
                <div class="code-name">.um-icon-direction-left
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-download"></span>
                <div class="name">
                    download
                </div>
                <div class="code-name">.um-icon-download
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-electronics"></span>
                <div class="name">
                    electronics
                </div>
                <div class="code-name">.um-icon-electronics
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-drag"></span>
                <div class="name">
                    drag
                </div>
                <div class="code-name">.um-icon-drag
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-elipsis"></span>
                <div class="name">
                    elipsis
                </div>
                <div class="code-name">.um-icon-elipsis
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-export"></span>
                <div class="name">
                    export
                </div>
                <div class="code-name">.um-icon-export
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-explain"></span>
                <div class="name">
                    explain
                </div>
                <div class="code-name">.um-icon-explain
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-edit"></span>
                <div class="name">
                    edit
                </div>
                <div class="code-name">.um-icon-edit
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-eye-close"></span>
                <div class="name">
                    eye-close
                </div>
                <div class="code-name">.um-icon-eye-close
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-email"></span>
                <div class="name">
                    email
                </div>
                <div class="code-name">.um-icon-email
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-error"></span>
                <div class="name">
                    error
                </div>
                <div class="code-name">.um-icon-error
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-favorite"></span>
                <div class="name">
                    favorite
                </div>
                <div class="code-name">.um-icon-favorite
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-file-common"></span>
                <div class="name">
                    file-common
                </div>
                <div class="code-name">.um-icon-file-common
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-file-delete"></span>
                <div class="name">
                    file-delete
                </div>
                <div class="code-name">.um-icon-file-delete
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-file-add"></span>
                <div class="name">
                    file-add
                </div>
                <div class="code-name">.um-icon-file-add
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-film"></span>
                <div class="name">
                    film
                </div>
                <div class="code-name">.um-icon-film
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-fabulous"></span>
                <div class="name">
                    fabulous
                </div>
                <div class="code-name">.um-icon-fabulous
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-file"></span>
                <div class="name">
                    file
                </div>
                <div class="code-name">.um-icon-file
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-folder-close"></span>
                <div class="name">
                    folder-close
                </div>
                <div class="code-name">.um-icon-folder-close
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-filter"></span>
                <div class="name">
                    filter
                </div>
                <div class="code-name">.um-icon-filter
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-good"></span>
                <div class="name">
                    good
                </div>
                <div class="code-name">.um-icon-good
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-hide"></span>
                <div class="name">
                    hide
                </div>
                <div class="code-name">.um-icon-hide
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-home"></span>
                <div class="name">
                    home
                </div>
                <div class="code-name">.um-icon-home
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-history"></span>
                <div class="name">
                    history
                </div>
                <div class="code-name">.um-icon-history
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-file-open"></span>
                <div class="name">
                    file-open
                </div>
                <div class="code-name">.um-icon-file-open
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-forward"></span>
                <div class="name">
                    forward
                </div>
                <div class="code-name">.um-icon-forward
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-import"></span>
                <div class="name">
                    import
                </div>
                <div class="code-name">.um-icon-import
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-image-text"></span>
                <div class="name">
                    image-text
                </div>
                <div class="code-name">.um-icon-image-text
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-keyboard-26"></span>
                <div class="name">
                    keyboard-26
                </div>
                <div class="code-name">.um-icon-keyboard-26
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-keyboard-9"></span>
                <div class="name">
                    keyboard-9
                </div>
                <div class="code-name">.um-icon-keyboard-9
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-link"></span>
                <div class="name">
                    link
                </div>
                <div class="code-name">.um-icon-link
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-layout"></span>
                <div class="name">
                    layout
                </div>
                <div class="code-name">.um-icon-layout
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-fullscreen-shrink"></span>
                <div class="name">
                    fullscreen-shrink
                </div>
                <div class="code-name">.um-icon-fullscreen-shrink
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-layers"></span>
                <div class="name">
                    layers
                </div>
                <div class="code-name">.um-icon-layers
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-lock"></span>
                <div class="name">
                    lock
                </div>
                <div class="code-name">.um-icon-lock
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-fullscreen-expand"></span>
                <div class="name">
                    fullscreen-expand
                </div>
                <div class="code-name">.um-icon-fullscreen-expand
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-map"></span>
                <div class="name">
                    map
                </div>
                <div class="code-name">.um-icon-map
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-meh"></span>
                <div class="name">
                    meh
                </div>
                <div class="code-name">.um-icon-meh
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-menu"></span>
                <div class="name">
                    menu
                </div>
                <div class="code-name">.um-icon-menu
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-loading"></span>
                <div class="name">
                    loading
                </div>
                <div class="code-name">.um-icon-loading
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-help"></span>
                <div class="name">
                    help
                </div>
                <div class="code-name">.um-icon-help
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-minus-circle"></span>
                <div class="name">
                    minus-circle
                </div>
                <div class="code-name">.um-icon-minus-circle
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-modular"></span>
                <div class="name">
                    modular
                </div>
                <div class="code-name">.um-icon-modular
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-notification"></span>
                <div class="name">
                    notification
                </div>
                <div class="code-name">.um-icon-notification
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-mic"></span>
                <div class="name">
                    mic
                </div>
                <div class="code-name">.um-icon-mic
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-more"></span>
                <div class="name">
                    more
                </div>
                <div class="code-name">.um-icon-more
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-pad"></span>
                <div class="name">
                    pad
                </div>
                <div class="code-name">.um-icon-pad
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-operation"></span>
                <div class="name">
                    operation
                </div>
                <div class="code-name">.um-icon-operation
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-play"></span>
                <div class="name">
                    play
                </div>
                <div class="code-name">.um-icon-play
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-print"></span>
                <div class="name">
                    print
                </div>
                <div class="code-name">.um-icon-print
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-mobile-phone"></span>
                <div class="name">
                    mobile-phone
                </div>
                <div class="code-name">.um-icon-mobile-phone
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-minus"></span>
                <div class="name">
                    minus
                </div>
                <div class="code-name">.um-icon-minus
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-navigation"></span>
                <div class="name">
                    navigation
                </div>
                <div class="code-name">.um-icon-navigation
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-pdf"></span>
                <div class="name">
                    pdf
                </div>
                <div class="code-name">.um-icon-pdf
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-prompt"></span>
                <div class="name">
                    prompt
                </div>
                <div class="code-name">.um-icon-prompt
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-move"></span>
                <div class="name">
                    move
                </div>
                <div class="code-name">.um-icon-move
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-refresh"></span>
                <div class="name">
                    refresh
                </div>
                <div class="code-name">.um-icon-refresh
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-run-up"></span>
                <div class="name">
                    run-up
                </div>
                <div class="code-name">.um-icon-run-up
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-picture"></span>
                <div class="name">
                    picture
                </div>
                <div class="code-name">.um-icon-picture
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-run-in"></span>
                <div class="name">
                    run-in
                </div>
                <div class="code-name">.um-icon-run-in
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-pin"></span>
                <div class="name">
                    pin
                </div>
                <div class="code-name">.um-icon-pin
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-save"></span>
                <div class="name">
                    save
                </div>
                <div class="code-name">.um-icon-save
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-search"></span>
                <div class="name">
                    search
                </div>
                <div class="code-name">.um-icon-search
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-share"></span>
                <div class="name">
                    share
                </div>
                <div class="code-name">.um-icon-share
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-scanning"></span>
                <div class="name">
                    scanning
                </div>
                <div class="code-name">.um-icon-scanning
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-security"></span>
                <div class="name">
                    security
                </div>
                <div class="code-name">.um-icon-security
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-sign-out"></span>
                <div class="name">
                    sign-out
                </div>
                <div class="code-name">.um-icon-sign-out
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-select"></span>
                <div class="name">
                    select
                </div>
                <div class="code-name">.um-icon-select
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-stop"></span>
                <div class="name">
                    stop
                </div>
                <div class="code-name">.um-icon-stop
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-success"></span>
                <div class="name">
                    success
                </div>
                <div class="code-name">.um-icon-success
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-smile"></span>
                <div class="name">
                    smile
                </div>
                <div class="code-name">.um-icon-smile
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-switch"></span>
                <div class="name">
                    switch
                </div>
                <div class="code-name">.um-icon-switch
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-setting"></span>
                <div class="name">
                    setting
                </div>
                <div class="code-name">.um-icon-setting
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-survey"></span>
                <div class="name">
                    survey
                </div>
                <div class="code-name">.um-icon-survey
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-task"></span>
                <div class="name">
                    task
                </div>
                <div class="code-name">.um-icon-task
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-skip"></span>
                <div class="name">
                    skip
                </div>
                <div class="code-name">.um-icon-skip
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-text"></span>
                <div class="name">
                    text
                </div>
                <div class="code-name">.um-icon-text
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-time"></span>
                <div class="name">
                    time
                </div>
                <div class="code-name">.um-icon-time
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-telephone-out"></span>
                <div class="name">
                    telephone-out
                </div>
                <div class="code-name">.um-icon-telephone-out
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-toggle-left"></span>
                <div class="name">
                    toggle-left
                </div>
                <div class="code-name">.um-icon-toggle-left
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-toggle-right"></span>
                <div class="name">
                    toggle-right
                </div>
                <div class="code-name">.um-icon-toggle-right
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-telephone"></span>
                <div class="name">
                    telephone
                </div>
                <div class="code-name">.um-icon-telephone
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-top"></span>
                <div class="name">
                    top
                </div>
                <div class="code-name">.um-icon-top
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-unlock"></span>
                <div class="name">
                    unlock
                </div>
                <div class="code-name">.um-icon-unlock
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-user"></span>
                <div class="name">
                    user
                </div>
                <div class="code-name">.um-icon-user
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-upload"></span>
                <div class="name">
                    upload
                </div>
                <div class="code-name">.um-icon-upload
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-work"></span>
                <div class="name">
                    work
                </div>
                <div class="code-name">.um-icon-work
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-training"></span>
                <div class="name">
                    training
                </div>
                <div class="code-name">.um-icon-training
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-warning"></span>
                <div class="name">
                    warning
                </div>
                <div class="code-name">.um-icon-warning
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-zoom-in"></span>
                <div class="name">
                    zoom-in
                </div>
                <div class="code-name">.um-icon-zoom-in
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-zoom-out"></span>
                <div class="name">
                    zoom-out
                </div>
                <div class="code-name">.um-icon-zoom-out
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-add-bold"></span>
                <div class="name">
                    add-bold
                </div>
                <div class="code-name">.um-icon-add-bold
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-arrow-left-bold"></span>
                <div class="name">
                    arrow-left-bold
                </div>
                <div class="code-name">.um-icon-arrow-left-bold
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-arrow-up-bold"></span>
                <div class="name">
                    arrow-up-bold
                </div>
                <div class="code-name">.um-icon-arrow-up-bold
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-close-bold"></span>
                <div class="name">
                    close-bold
                </div>
                <div class="code-name">.um-icon-close-bold
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-arrow-down-bold"></span>
                <div class="name">
                    arrow-down-bold
                </div>
                <div class="code-name">.um-icon-arrow-down-bold
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-minus-bold"></span>
                <div class="name">
                    minus-bold
                </div>
                <div class="code-name">.um-icon-minus-bold
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-arrow-right-bold"></span>
                <div class="name">
                    arrow-right-bold
                </div>
                <div class="code-name">.um-icon-arrow-right-bold
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-select-bold"></span>
                <div class="name">
                    select-bold
                </div>
                <div class="code-name">.um-icon-select-bold
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-arrow-up-filling"></span>
                <div class="name">
                    arrow-up-filling
                </div>
                <div class="code-name">.um-icon-arrow-up-filling
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-arrow-down-filling"></span>
                <div class="name">
                    arrow-down-filling
                </div>
                <div class="code-name">.um-icon-arrow-down-filling
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-arrow-left-filling"></span>
                <div class="name">
                    arrow-left-filling
                </div>
                <div class="code-name">.um-icon-arrow-left-filling
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-arrow-right-filling"></span>
                <div class="name">
                    arrow-right-filling
                </div>
                <div class="code-name">.um-icon-arrow-right-filling
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-caps-unlock-filling"></span>
                <div class="name">
                    caps-unlock-filling
                </div>
                <div class="code-name">.um-icon-caps-unlock-filling
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-comment-filling"></span>
                <div class="name">
                    comment-filling
                </div>
                <div class="code-name">.um-icon-comment-filling
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-check-item-filling"></span>
                <div class="name">
                    check-item-filling
                </div>
                <div class="code-name">.um-icon-check-item-filling
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-clock-filling"></span>
                <div class="name">
                    clock-filling
                </div>
                <div class="code-name">.um-icon-clock-filling
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-delete-filling"></span>
                <div class="name">
                    delete-filling
                </div>
                <div class="code-name">.um-icon-delete-filling
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-decline-filling"></span>
                <div class="name">
                    decline-filling
                </div>
                <div class="code-name">.um-icon-decline-filling
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-dynamic-filling"></span>
                <div class="name">
                    dynamic-filling
                </div>
                <div class="code-name">.um-icon-dynamic-filling
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-intermediate-filling"></span>
                <div class="name">
                    intermediate-filling
                </div>
                <div class="code-name">.um-icon-intermediate-filling
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-favorite-filling"></span>
                <div class="name">
                    favorite-filling
                </div>
                <div class="code-name">.um-icon-favorite-filling
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-layout-filling"></span>
                <div class="name">
                    layout-filling
                </div>
                <div class="code-name">.um-icon-layout-filling
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-help-filling"></span>
                <div class="name">
                    help-filling
                </div>
                <div class="code-name">.um-icon-help-filling
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-history-filling"></span>
                <div class="name">
                    history-filling
                </div>
                <div class="code-name">.um-icon-history-filling
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-filter-filling"></span>
                <div class="name">
                    filter-filling
                </div>
                <div class="code-name">.um-icon-filter-filling
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-file-common-filling"></span>
                <div class="name">
                    file-common-filling
                </div>
                <div class="code-name">.um-icon-file-common-filling
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-news-filling"></span>
                <div class="name">
                    news-filling
                </div>
                <div class="code-name">.um-icon-news-filling
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-edit-filling"></span>
                <div class="name">
                    edit-filling
                </div>
                <div class="code-name">.um-icon-edit-filling
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-fullscreen-expand-filling"></span>
                <div class="name">
                    fullscreen-expand-filling
                </div>
                <div class="code-name">.um-icon-fullscreen-expand-filling
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-smile-filling"></span>
                <div class="name">
                    smile-filling
                </div>
                <div class="code-name">.um-icon-smile-filling
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-rise-filling"></span>
                <div class="name">
                    rise-filling
                </div>
                <div class="code-name">.um-icon-rise-filling
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-picture-filling"></span>
                <div class="name">
                    picture-filling
                </div>
                <div class="code-name">.um-icon-picture-filling
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-notification-filling"></span>
                <div class="name">
                    notification-filling
                </div>
                <div class="code-name">.um-icon-notification-filling
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-user-filling"></span>
                <div class="name">
                    user-filling
                </div>
                <div class="code-name">.um-icon-user-filling
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-setting-filling"></span>
                <div class="name">
                    setting-filling
                </div>
                <div class="code-name">.um-icon-setting-filling
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-switch-filling"></span>
                <div class="name">
                    switch-filling
                </div>
                <div class="code-name">.um-icon-switch-filling
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-work-filling"></span>
                <div class="name">
                    work-filling
                </div>
                <div class="code-name">.um-icon-work-filling
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-task-filling"></span>
                <div class="name">
                    task-filling
                </div>
                <div class="code-name">.um-icon-task-filling
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-success-filling"></span>
                <div class="name">
                    success-filling
                </div>
                <div class="code-name">.um-icon-success-filling
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-warning-filling"></span>
                <div class="name">
                    warning-filling
                </div>
                <div class="code-name">.um-icon-warning-filling
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-folder-filling"></span>
                <div class="name">
                    folder-filling
                </div>
                <div class="code-name">.um-icon-folder-filling
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-map-filling"></span>
                <div class="name">
                    map-filling
                </div>
                <div class="code-name">.um-icon-map-filling
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-prompt-filling"></span>
                <div class="name">
                    prompt-filling
                </div>
                <div class="code-name">.um-icon-prompt-filling
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-meh-filling"></span>
                <div class="name">
                    meh-filling
                </div>
                <div class="code-name">.um-icon-meh-filling
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-cry-filling"></span>
                <div class="name">
                    cry-filling
                </div>
                <div class="code-name">.um-icon-cry-filling
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-top-filling"></span>
                <div class="name">
                    top-filling
                </div>
                <div class="code-name">.um-icon-top-filling
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-home-filling"></span>
                <div class="name">
                    home-filling
                </div>
                <div class="code-name">.um-icon-home-filling
                </div>
                </li>
                
                <li class="dib">
                <span class="icon iconfont um-icon-sorting"></span>
                <div class="name">
                    sorting
                </div>
                <div class="code-name">.um-icon-sorting
                </div>
                </li>
                
            </ul>
        </div>
        
        <p>来源: https://www.iconfont.cn/</p>
    </div>
</template>

<script>
export default {
    data() {
        return {
            data:`<span class="um-icon-add"></span> `
        }
    }
}
</script>

<style lang="scss">
.icon_block {
    display: block;
}

.icon_lists {
    width: 100% !important;
    overflow: hidden;
    *zoom: 1;
}

.icon_lists li {
    display: inline-block;
    width: 100px;
    margin-bottom: 10px;
    margin-right: 20px;
    text-align: center;
    list-style: none !important;
    cursor: default;
    vertical-align: top;
    .name {
        display: none;
    }
}

.icon_lists li .code-name {
    line-height: 1.2;
    font-size: 12px;
}

.icon_lists .icon {
    display: block;
    height: 100px;
    line-height: 100px;
    font-size: 42px;
    margin: 10px auto;
    color: #333;
    -webkit-transition: font-size 0.25s linear, width 0.25s linear;
    -moz-transition: font-size 0.25s linear, width 0.25s linear;
    transition: font-size 0.25s linear, width 0.25s linear;
}

.icon_lists .icon:hover {
    font-size: 100px;
}

.icon_lists li .name,
.icon_lists li .code-name {
    color: #666;
}
</style>